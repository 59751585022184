import { useState } from 'react';
import './navbar.css';

const Navbar = () => {

    const [toggleNavbar, updateToggleNavbar] = useState(false);

    const toggleNav = (type) => {
        updateToggleNavbar(type)
    }

    return (
        <div className={toggleNavbar ? 'navbar-wrapper bx-shadow' : 'navbar-wrapper'}>
            <div className='cstm-nav'>
                <a href='#home'>
                    <div className='cstm-nav-head'>
                        Flutter Me Shutter
                        <p className='cstm-nav-head-slogan'>Capture your memories with 3D impressions</p>
                    </div>
                </a>
                <div className="cstm-nav-list hide-lg">
                    <a href='#about-us'><div className='cstm-nav-item' onClick={() => { toggleNav(false) }}>About Us</div></a>
                    <a href='#our-products'><div className='cstm-nav-item' onClick={() => { toggleNav(false) }}>Our Products</div></a>
                    <a href='#testimonials'><div className='cstm-nav-item' onClick={() => { toggleNav(false) }}>Testimonials</div></a>
                    <a href='#contact-us'><div className='cstm-nav-item' onClick={() => { toggleNav(false) }}>Contact Us</div></a>
                </div>
                <i className="fa fa-bars hide-sm" onClick={() => { toggleNav(!toggleNavbar) }}></i>
            </div>
            <div className={toggleNavbar ? 'cstm-nav-list' : 'cstm-nav-list cstm-nav-list-sm hide-sm'}>
                <a href='#about-us'><div className='cstm-nav-item' onClick={() => { toggleNav(false) }}>About Us</div></a>
                <a href='#our-products'><div className='cstm-nav-item' onClick={() => { toggleNav(false) }}>Our Products</div></a>
                <a href='#testimonials'><div className='cstm-nav-item' onClick={() => { toggleNav(false) }}>Testimonials</div></a>
                <a href='#contact-us'><div className='cstm-nav-item' onClick={() => { toggleNav(false) }}>Contact Us</div></a>
            </div>
        </div>
    )
}

export default Navbar;