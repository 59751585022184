import './footer.css';

const Footer = () => {
    return (
        <div className='footer-wrapper' id='contact-us'>
            <div class="footer-box">
                <div class="footer-card">
                    <i class="fa fa-map-marker"></i>
                    <span class="footer-text">Bareilly, Uttar Pradesh</span>
                </div>
                <div class="footer-card">
                    <i class="fa fa-mobile-phone"></i>
                    <a href='tel:+918755761776'><span class="footer-text">+918755761776</span></a>
                </div>
                <div class="footer-card">
                    <i class="fa fa-envelope-o"></i>
                    <a href='mailto:contact.fluttermeshutter@gmail.com'><span class="footer-text">contact.fluttermeshutter@gmail.com</span></a>
                </div>
            </div>
            <div class="copyright"><i class="fa fa-copyright"></i> 2023 - Flutter Me Shutter - Developed By - <a href='https://ambargupta.com' target="_blank" rel="noreferrer"><span className='footer-developer'> Ambar Gupta</span></a></div>
        </div>
    )
}

export default Footer;