import './landing-page.css';
// import IMAGE1 from '../../asssets/images/casting-image/image1.jpeg';
// import IMAGE2 from '../../asssets/images/casting-image/image2.png';
// import IMAGE3 from '../../asssets/images/casting-image/image3.png';
// import IMAGE4 from '../../asssets/images/casting-image/image4.png';
// import IMAGE5 from '../../asssets/images/casting-image/image5.png';
// import IMAGE6 from '../../asssets/images/casting-image/image6.jpg';
// import IMAGE7 from '../../asssets/images/casting-image/image7.jpg';
// import IMAGE8 from '../../asssets/images/casting-image/image8.jpg';
// import IMAGE9 from '../../asssets/images/casting-image/image9.jpg';
// import IMAGE10 from '../../asssets/images/casting-image/image10.jpeg';
// import IMAGEBG from '../../asssets/images/bg-intro.png';


const LandingPage = () => {
    return (
        <div className='landing-page-wrapper' id='home'>
            <div className='intro-section'>
                <div className='intro-box'>
                </div>
            </div>
            <div className='about-us-section' id='about-us'>
                <div className='about-us-box'>
                    <h1>About Us</h1>
                    <hr className='hr'></hr>
                    <p>
                        Flutter Me Shutter incepted in the year 2017 aims to embalm the memories of your beloved ones by capturing
                        their impressions exactly as replicas of hands and feet in 3D castings and storing them forever in your homes and offices.
                    </p>
                    <p>
                        We aim to create a time capsule by designing a bespoke product with you and your baby and your family, that includes 3D
                        impressions and photographs assembeled in a unqiue and modern frame.
                        The casting keepsakes are true to life and capture fond loving memories for years together.
                    </p>
                    <p>
                        We use latest methods and techniques to give you the chance to treasure the feeling of reverence of your parents, your guru's and your family.
                    </p>
                    <p className='bold-text'><q><cite>Capture your memories with 3D impressions</cite></q></p>
                </div>
            </div>
            <div className='our-products-section' id='our-products'>
                <div className='our-products-box'>
                    <h1>Our Products</h1>
                    <hr className='hr'></hr>
                    <div className='product-boxes'>
                        <div className='product-box'>
                            <p className='product-box-head'>Baby</p>
                            <span className='product-box-sub-head'>For single baby</span>
                            <div className='product-box-list'>
                                <p className='product-box-item'>Single Hand - <span className='product-box-item-price'>Rs 4,000</span></p>
                                <p className='product-box-item'>Single Feet - <span className='product-box-item-price'>Rs 4,000</span></p>
                                <p className='product-box-item'>Both Hand - <span className='product-box-item-price'>Rs 5,000</span></p>
                                <p className='product-box-item'>Both Feet - <span className='product-box-item-price'>Rs 5,000</span></p>
                            </div>
                        </div>
                        <div className='product-box'>
                            <p className='product-box-head'>Siblings</p>
                            <span className='product-box-sub-head'>For 2 siblings</span>
                            <div className='product-box-list'>
                                <p className='product-box-item'>Single Hand - <span className='product-box-item-price'>Rs 6,000</span></p>
                                <p className='product-box-item'>Single Feet - <span className='product-box-item-price'>Rs 6,000</span></p>
                                <p className='product-box-item'>Both Hand - <span className='product-box-item-price'>Rs 8,000</span></p>
                                <p className='product-box-item'>Both Feet - <span className='product-box-item-price'>Rs 8,000</span></p>
                            </div>
                        </div>
                        <div className='product-box'>
                            <p className='product-box-head'>Couple</p>
                            <span className='product-box-sub-head'>For 2 person</span>
                            <div className='product-box-list'>
                                <p className='product-box-item'>Single Hand - <span className='product-box-item-price'>Rs 6,000</span></p>
                                <p className='product-box-item'>Single Feet - <span className='product-box-item-price'>Rs 6,000</span></p>
                                <p className='product-box-item'>Both Hand - <span className='product-box-item-price'>Rs 8,000</span></p>
                                <p className='product-box-item'>Both Feet - <span className='product-box-item-price'>Rs 8,000</span></p>
                            </div>
                        </div>
                        <div className='product-box'>
                            <p className='product-box-head'>Famliy</p>
                            <span className='product-box-sub-head'>For 4 person</span>
                            <div className='product-box-list'>
                                <p className='product-box-item'>Single Hand - <span className='product-box-item-price'>Rs 10,000</span></p>
                                <p className='product-box-item'>Single Feet - <span className='product-box-item-price'>Rs 10,000</span></p>
                                <p className='product-box-item'>Both Hand - <span className='product-box-item-price'>Rs 15,000</span></p>
                                <p className='product-box-item'>Both Feet - <span className='product-box-item-price'>Rs 15,000</span></p>
                            </div>
                        </div>
                        <div className='product-box'>
                            <p className='product-box-head'>Guruji</p>
                            <span className='product-box-sub-head'>For 1 person</span>
                            <div className='product-box-list'>
                                <p className='product-box-item'>Single Hand - <span className='product-box-item-price'>Rs 15,000</span></p>
                                <p className='product-box-item'>Single Feet - <span className='product-box-item-price'>Rs 15,000</span></p>
                                <p className='product-box-item'>Both Hand - <span className='product-box-item-price'>Rs 20,000</span></p>
                                <p className='product-box-item'>Both Feet - <span className='product-box-item-price'>Rs 20,000</span></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='testimonials-section' id='testimonials'>
                <div className='testimonials-box'>
                    <h1>Testimonials</h1>
                    <hr className='hr'></hr>
                    <div className='testimonial-card-box'>
                        <div className='testimonial-card'>
                            <p>Very Good, product and service. My kids really enjoyed the whole process of making there 3D impressions.</p>
                            <p className='mb-0'><i className='fa fa-user-circle'></i>Anamika - Bareilly</p>
                        </div>
                        <div className='testimonial-card'>
                            <p>Quality is something I was concerned about for my kids. But after the product was ready, my thinking really changed.</p>
                            <p className='mb-0'><i className='fa fa-user-circle'></i>Priyanka - Bareilly</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LandingPage;