import './App.css';
import Footer from './components/footer';
import LandingPage from './components/landing-page';
import Navbar from './components/navbar';


const App = () => {
  return (
    <div className='app-wrapper'>
      <Navbar></Navbar>
      <LandingPage></LandingPage>
      <Footer></Footer>
    </div>
  )
}

export default App